// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// custom CSS styles
import "./src/css/main.css";

// custom CSS styles
var hljsCurl = require('highlightjs-curl');
// custom plugin 
const hljs = require('highlight.js/lib/core')
const javascript = require('highlight.js/lib/languages/javascript')
const java = require('highlight.js/lib/languages/java')
const python = require('highlight.js/lib/languages/python')
const swift = require('highlight.js/lib/languages/swift')
const json = require('highlight.js/lib/languages/json')
const css = require('highlight.js/lib/languages/css')
const xml = require('highlight.js/lib/languages/xml')
const plaintext = require('highlight.js/lib/languages/plaintext')
const cSharp = require('highlight.js/lib/languages/csharp')
const objectiveC = require('highlight.js/lib/languages/objectivec')
const php = require('highlight.js/lib/languages/php')

hljs.registerLanguage('javascript', javascript)
hljs.registerLanguage('css', css)
hljs.registerLanguage('xml', xml)
hljs.registerLanguage('java', java)
hljs.registerLanguage('python', python)
hljs.registerLanguage('swift', swift)
hljs.registerLanguage('json', json)
hljs.registerLanguage('text', plaintext)
hljs.registerLanguage('csharp', cSharp)
hljs.registerLanguage('curl', hljsCurl)
hljs.registerLanguage('objectivec', objectiveC)
hljs.registerLanguage('php', php)
